<template>
  <div class=" container">
    <v-row style="width: 100%">
      <v-col cols="10" offset-lg="1" offset-md="1" class="mt-4">
        <Breadcrumbs
          :items="[
            {
              text: 'All Staff',
              disabled: false,
              href: '/admin/staffs/all'
            },
            {
              text: details && details._id ? details._id.name : '',
              disabled: true,
              href: ''
            },
            {
              text: !!$route.query.id ? 'Transactions' : 'Add Staff',
              disabled: true,
              href: !!$route.query.id
                ? '/admin/staffs/add'
                : '/admin/staffs/all'
            }
          ]"
        />
        <v-row>
          <v-col cols="12">
            <div class="d-flex flex-row flex-grow-1 flex-shrink-1 flex-wrap">
              <v-card
                class="box-shadow-light d-flex flex-row flex-wrap flex-grow-1 pa-3 ma-1"
              >
                <div class="d-flex flex-column">
                  <v-avatar color="rgba(9, 55, 255, 0.21)" size="60">
                    <i
                      class="material-icons-outlined"
                      style="color: #0937ff !important"
                      >account_circle</i
                    >
                  </v-avatar>
                </div>
                <div class="d-flex flex-column">
                  <div class="d-flex flex-row flex-full flex-wrap">
                    <h5
                      class="font text-uppercase font-weight-medium font-md mx-3 mt-1 grey--text"
                    >
                      {{ details && details._id ? details._id.name : "" }}
                    </h5>
                  </div>

                  <div class="d-flex flex-row flex-full flex-wrap">
                    <span
                      v-if="details && details._id"
                      class="font font-md font-weight-medium ml-3"
                      >Acc/No.: {{ details._id.accountNumber }}</span
                    >
                  </div>
                  <div class="d-flex flex-row flex-full flex-wrap">
                    <span
                      v-if="details && details._id"
                      class="font font-md font-weight-medium ml-3"
                      >Phone No.: {{ details._id.phone }}</span
                    >
                  </div>
                </div>
              </v-card>
              <v-card
                class="box-shadow-light d-flex flex-row flex-wrap flex-grow-1 pa-3 ma-1"
              >
                <div class="d-flex flex-column">
                  <v-avatar color="rgba(9, 55, 255, 0.21)" size="60">
                    <i
                      class="material-icons-outlined"
                      style="color: #0937ff !important"
                      >shopping_basket</i
                    >
                  </v-avatar>
                </div>
                <div class="d-flex flex-column">
                  <div class="d-flex flex-row flex-full flex-wrap">
                    <h5
                      class="font font-weight-medium font-md mx-3 mt-1 grey--text"
                    >
                      Overall Total Transactions
                    </h5>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          small
                          color="#09c711"
                          class="vc--verified-icon"
                          >verified_user</v-icon
                        >
                      </template>
                      <span class="ft font-weight-medium"
                        >Verified Suppliers</span
                      >
                    </v-tooltip>
                  </div>

                  <div class="d-flex flex-row flex-full flex-wrap">
                    <span
                      v-if="details && details.totalAmount"
                      class="font font-size-lg font-weight-bold ml-3"
                      >{{ details.totalAmount | currencyFormat }}</span
                    >
                    <span v-else class="font font-size-lg font-weight-bold ml-3"
                      >0</span
                    >
                  </div>
                </div>
              </v-card>

              <v-card
                v-if="date"
                class="box-shadow-light d-flex flex-row flex-wrap flex-grow-1 pa-3 ma-1"
              >
                <div class="d-flex flex-column">
                  <v-avatar color="rgba(227, 77, 254, 0.22)" size="60">
                    <i
                      class="material-icons-outlined"
                      style="color: #af06e4 !important;"
                      >credit_card</i
                    >
                  </v-avatar>
                </div>
                <div class="d-flex flex-column">
                  <div class="d-flex flex-row flex-full flex-wrap">
                    <h4
                      class="font font-weight-medium font-md mx-3 mt-1 black--text"
                    >
                      Total Transactions for {{ date }}
                    </h4>
                  </div>
                  <div class="d-flex flex-row flex-full flex-wrap">
                    <span
                      v-if="details && details.totalAmountByDate"
                      class="font font-weight-bold font-size-lg text-lg ml-3"
                      >{{
                        details.totalAmountByDate.totalAmount | currencyFormat
                      }}</span
                    >
                  </div>
                </div>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12">
            <v-tabs v-model="tab" class=" grey">
              <v-tabs-slider></v-tabs-slider>

              <v-tab href="#tab-1" class=" font">
                Transactions
              </v-tab>
              <v-spacer />

              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    label
                    :close="date !== null"
                    @click:close="date = null"
                    :color="date !== null ? 'primary' : 'white'"
                    class="box-shadow-light mt-2 mr-2 font text-uppercase font-weight-medium"
                  >
                    <i class="material-icons-outlined font-size-md mr-2"
                      >event</i
                    >
                    {{ date ? date : "Choose Date " }}
                  </v-chip>
                </template>
                <v-date-picker
                  class=" font font-weight-medium font-size-sm"
                  v-model="date"
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item value="tab-1">
                <div class=" d-flex flex-column flex-grow-1"></div>
                <div class=" d-flex flex-column flex-grow-1 mt-2 ">
                  <v-data-table
                    :loading="pageLoading"
                    :headers="headers"
                    :options.sync="options"
                    :server-items-length="pagination.total"
                    :items="transactions"
                    :footer-props="{
                      itemsPerPageOptions: [10, 20, 30]
                    }"
                    class="box-shadow-light font font-weight-medium font-size-sm"
                  >
                    <template #item.createdAt="{item}">
                      {{ item.createdAt | timestamp }}
                    </template>
                    <template #item.memberId="{item}">
                      <v-chip label small color="primary">
                        <span
                          class=" font font-weight-medium font-sm text-uppercase"
                          >{{ item.memberId && item.memberId.lastName }}
                          {{ item.memberId && item.memberId.firstName }}</span
                        >
                      </v-chip>
                    </template>
                    <template #item.service="{item}">
                      <v-chip label small color="primary">
                        <span
                          class=" font font-weight-medium font-sm text-uppercase"
                          >{{ item.service }}</span
                        >
                      </v-chip>
                    </template>
                  </v-data-table>
                </div>
              </v-tab-item>
              <v-tab-item value="tab-2"> </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />
  </div>
</template>
<script>
// import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import SnackBarMixins from "../../../../mixins/SnackBarMIxins";
import { loadComponent } from "@/utils/helpers";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  components: {
    // ValidationProvider,
    // ValidationObserver,
    SnackBar: loadComponent(`component/SnackBar`),
    // PageTitle: loadComponent(`component/PageTitle`),
    Breadcrumbs: loadComponent(`component/Breadcrumbs`)
  },
  computed: {
    ...mapGetters({
      pageLoading: "getIsPageLoading",
      details: "staffs/getStaffDetails",
      transactions: "staffs/getAllTransactions",
      pagination: "staffs/getPagination"
    })
  },
  mixins: [SnackBarMixins],
  data() {
    return {
      tab: 0,
      menu: false,
      date: null,
      staff: {
        name: "",
        phone: "",
        accountNumber: ""
      },
      headers: [
        { text: "Date", value: "createdAt" },
        { text: "Member", value: "memberId" },
        { text: "Acc/No.", value: "accountNumber" },
        { text: "Amt.", value: "totalAmount" },
        { text: "Service", value: "service" }
      ],
      options: {}
    };
  },
  filters: {
    timestamp(value) {
      return moment(value).format("YYYY-MM-DD HH:mm A");
    },
    currencyFormat(value) {
      return new Intl.NumberFormat("en-GB", {
        currency: "GHS",
        style: "currency",
        minimumFractionDigits: 2
      }).format(value);
    }
  },
  watch: {
    details(payload) {
      const { name, phone, accountNumber } = payload;
      this.staff = { name, phone, accountNumber };
    },
    date(value) {
      this.$store.dispatch("staffs/transactions", {
        id: this.$route.query.id,
        query: `?page=1&size=${this.options.itemsPerPage ?? 10}${
          value ? "&date=" + value : ""
        }`
      });
    },
    options(option) {
      const { itemsPerPage, page } = option;
      this.$store.dispatch("staffs/transactions", {
        id: this.$route.query.id,
        query: `?page=${page}&size=${itemsPerPage}${
          this.date ? "&date=" + this.date : ""
        }`
      });
    }
  },
  methods: {
    addNewStaff() {
      if (this.$route.query.id) {
        this.$store.dispatch("staffs/update", {
          _id: this.$route.query.id,
          ...this.staff
        });
        return;
      }
      this.$store.dispatch("staffs/create", {
        ...this.staff
      });
    }
  },
  created() {
    if (this.$route.query.id) {
      this.$store.dispatch("staffs/transactions", {
        id: this.$route.query.id,
        query: `?page=1&size=10`
      });
    }
  }
};
</script>
